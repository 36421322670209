import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const AtomicSimReportModule = new Module({
  id: 'atomic-sims-report',
  name: 'Atomic Sims Report',
  path: 'atomic-sims',
  Component: ComponentLoader(
    lazy(() => import('src/content/Report/AtomicSimStatus/View'))
  ),
});

const AtomicSimReportModules = [AtomicSimReportModule];

export default new ModuleBundle('AtomicSimReport', AtomicSimReportModules);

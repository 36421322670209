import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const MNOJobReportModule = new Module({
  id: 'mno-job-report',
  name: 'MNO Job Report',
  path: 'mno-job-report',
  Component: ComponentLoader(
    lazy(() => import('src/content/Report/MNOJob/View'))
  )
});

const MNOJobReportModules = [MNOJobReportModule];

export default new ModuleBundle('PublicApiLog', MNOJobReportModules);

import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const ReplaceSimViewModule = new Module({
  id: 'replace-sim-operation',
  name: 'Replace Sim',
  path: 'replace-sim',
  Component: ComponentLoader(
    lazy(() => import('src/content/ReplaceSim/View'))
  )
});

const ReplaceSimModule = [ReplaceSimViewModule];

export default new ModuleBundle('ReplaceSim', ReplaceSimModule);

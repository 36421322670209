import registerModulesWithRouter from 'src/config/AccessControl/registerModulesWithRouter';
import AuditTrailModules from 'src/content/Report/AuditTrail/module';
import PublicApiLogModules from 'src/content/Report/PublicApiLog/module';
import TransactionReportModules from 'src/content/Report/TransactionReport/module';
import RequestDetailsReportModules from 'src/content/Report/RequestDetails/module';
import MNOJobReportModules from 'src/content/Report/MNOJob/module';
import AtomicSimReportModules from 'src/content/Report/AtomicSimStatus/module';
import ExpirySimReportModules from 'src/content/Report/SimExpiryReport/module';

const ReportRoutes = [
  registerModulesWithRouter(AuditTrailModules),
  registerModulesWithRouter(PublicApiLogModules),
  registerModulesWithRouter(TransactionReportModules),
  registerModulesWithRouter(MNOJobReportModules),
  registerModulesWithRouter(AtomicSimReportModules),
  registerModulesWithRouter(ExpirySimReportModules),
  registerModulesWithRouter(RequestDetailsReportModules)
];

export default ReportRoutes;

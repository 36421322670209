import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const ExpirySimReportModule = new Module({
  id: 'expiry-sims-report',
  name: 'Expiry Sims Report',
  path: 'expiry-sims',
  Component: ComponentLoader(
    lazy(() => import('src/content/Report/SimExpiryReport/View'))
  ),
});

const ExpirySimReportModules = [ExpirySimReportModule];

export default new ModuleBundle('ExpirySimReport', ExpirySimReportModules);
